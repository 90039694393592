import {computed, Inject, Injectable, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, firstValueFrom, map, Observable, take} from "rxjs";
import {PropertyDetails} from "../../interfaces/propertyDetails";
import {Meta, Title} from "@angular/platform-browser";
import {SkimlinksService} from "../tracking/skimlinks.service";
import {isPlatformBrowser} from "@angular/common";
import {FooterService} from "../footer/footer.service";
import {Router} from "@angular/router";
import {SpaceDetails} from "../../interfaces/spaceDetails";
import AOS from "aos";
import {Showroom} from "../../interfaces/showroom.interface";
import {CarouselComponent} from "../../shared/display-data/carousel/carousel.component";

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(
    private http: HttpClient,
    public title: Title,
    public meta: Meta,
    public skimlinksService: SkimlinksService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public footerService: FooterService,
    public router: Router,
  ) {

  }


  /**
   * PROPERTY DETAILS OF CURRENT SHOWROOM
   */
  PROPERTY_DETAILS: WritableSignal<PropertyDetails | null> = signal<PropertyDetails | null>(null)

  /**
   * List of spaces with products in them
   */
  SPACE_LIST = computed(() => {
    if(!this.PROPERTY_DETAILS()) {
      return []
    }
    return this.PROPERTY_DETAILS()!.spaceList.filter(each => !!each.productCount);
  })

  /**
   * Whether to disable spaces button in header
   */
  DISABLE_SPACES_BUTTON_HEADER = computed(() => {
    return !this.SPACE_LIST().length;
  })

  /**
   * Whether space image is available for at least one space or not
   */
  SPACE_IMAGE_AVAILABLE = computed(() => {
    return this.SPACE_LIST().some(each => !!each.image);
  })

  /**
   * SHOWROOM DETAILS
   */
  SHOWROOM_DETAILS = computed(() => {
    return this.PROPERTY_DETAILS()?.showroomDetails!;
  })

  /**
   * LIST OF SHOWROOMS
   */
  SHOWROOM_LIST: WritableSignal<Showroom[]> = signal([]);

  /**
   * Whether to show book a stay floating CTA
   */
  SHOW_BOOK_A_STAY: WritableSignal<boolean> = signal(false);

  /**
   * Currently selected space
   */
  SELECTED_SPACE:  WritableSignal<SpaceDetails | undefined> = signal<SpaceDetails | undefined>(undefined)

  /**
   * Whether api has been called or not for homepage details
   */
  isPageLoad: WritableSignal<boolean> = signal(false)

  /**
   * To stop/enable scroll on route navigate
   */
  SHOULD_SCROLL: WritableSignal<boolean> = signal(true)

  CLICK_PRODUCT_ID = signal<string>('')

  // Whether the page is loaded in an iframe
  _IS_IFRAME = signal(environment.isPreview)

  // Current active screen
  ACTIVE_SCREEN = 'homepage';

  /**
   * [GET] API to fetch homepage info
   * @param propertyId - Property id
   */
  getBasePageInfo(propertyId: string): Observable<PropertyDetails> {
    let params = new HttpParams();
    params = params.append('newShowroomUrl', propertyId);
    params = params.append('isPreview', this._IS_IFRAME());
    return this.http.get(`${environment.apiUrl}/showroom/homepage/details`, {params})
      .pipe(take(1), map((response: any) => {
        this.PROPERTY_DETAILS.set(response.data);
        this.isPageLoad.set(true);
        const data: PropertyDetails = response.data
        const showroomDetails = data.showroomDetails
        this.updateMetaAndTitleTags(`Shop Your Stay | ${showroomDetails?.showroomName!}`, showroomDetails?.image!)
        this.footerService.PropertyId.set(propertyId);
        this.footerService.PROPERTY_DETAILS.set({id: data.propertyId, companyId: data.companyId})
        if(isPlatformBrowser(this.platformId)) {
          this.skimlinksService.loadAffliateLinks(`${propertyId}-${response.data.propertyId}`);
        }
        console.log(response.data)
        return response.data;
      }), catchError((err) => {
        this.isPageLoad.set(true);
        throw err
      }));
  }


  /**
   * Async function to get property details based on property ID
   * @param propertyId Property ID
   */
  async fetchPageInformation(propertyId: string) {
    await firstValueFrom(this.getBasePageInfo(propertyId)).catch(() => {
      this.router.navigate(['/error'])
    });
  }

  /**
   * [GET] Request to fetch homepage info
   * @param propertyId - unique property id
   */
  getShowroomList(propertyId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('newShowroomUrl', propertyId);
    params = params.append('isPreview', this._IS_IFRAME());
    return this.http.get(`${environment.apiUrl}/homepage/showroom/list`, {params})
      .pipe(take(1), map((response: any) => {
        this.SHOWROOM_LIST.set(response.data.showroomList)
        return response.data;
      }), catchError((err) => {
        throw err
      }));
  }

  async fetchShowroomList(propertyId: string) {
    await firstValueFrom(this.getShowroomList(propertyId)).catch(() => {
      console.error('Unable to Fetch Showroom List')
    });
  }

  /**
   * Update Meta and Title Tags
   * @param title Page title
   * @param image Page hero image
   */
  updateMetaAndTitleTags(title: string, image: string) {

    const routerUrl = this.router.url;
    const currentUrl = `${environment.websiteUrl}/shop${routerUrl}`;

    // URL
    this.addOrEditMetaTag({property: 'og:url', content: currentUrl})
    this.addOrEditMetaTag({property: 'twitter:url', content: currentUrl})

    // Title
    this.title.setTitle(title);
    this.addOrEditMetaTag({property: 'title', content: title});
    this.addOrEditMetaTag({property: 'twitter:title', content: title});
    this.addOrEditMetaTag({property: 'og:title', content: title});

    // Hero image
    this.addOrEditMetaTag({property: 'og:image', content: image});
    this.addOrEditMetaTag({property: 'twitter:image', content: image});


    // Description
    const description = 'Bring home a piece of your stay with Minoan. Explore carefully curated home furnishings and decor and shop the products you want to take home with you.'
    this.addOrEditMetaTag({property: 'description', content: description});
    this.addOrEditMetaTag({property: 'og:description', content: description});
    this.addOrEditMetaTag({property: 'twitter:description', content: description});

    // Random
    this.addOrEditMetaTag({property: 'twitter:card', content: 'summary_large_image'});
    this.addOrEditMetaTag({property: 'og:type', content: 'website'});
    this.addOrEditMetaTag({property: 'twitter:domain', content: environment.minoanWebsiteUrl.replaceAll('https://www.', '')})

    this.addEditCanonical({rel: 'canonical', content: currentUrl})
  }

  /**
   * If meta tag exists, modifies existing tag content, else creates a new meta tag with the supplied information
   * @param information
   */
  addOrEditMetaTag(information: {property: string, content: string}) {
    const metaTag = this.meta.getTag(`property='${information.property}'`)
    if(metaTag) {
      if(information.content) {
        this.meta.removeTag(`property='${information.property}'`)
        this.meta.addTag(information)
      } else {
        this.meta.removeTag(`property='${information.property}'`)
      }
    } else {
      this.meta.addTag(information)
    }
  }

  /**
   * Add/edit canonical meta tag
   * @param information
   */
  addEditCanonical(information: {rel: string, content: string}) {
    const metaTag = this.meta.getTag(`rel='${information.rel}'`)
    if(metaTag) {
      if(information.content) {
        this.meta.removeTag(`rel='${information.rel}'`)
        this.meta.addTag(information)
      } else {
        this.meta.removeTag(`rel='${information.rel}'`)
      }
    } else {
      this.meta.addTag(information)
    }
  }

  /**
   * To init AOS when app starts on browser
   */
  initAOS() {
    AOS.init({
      duration: 2500, once: true,
    });
  }

  /**
   * To hard refresh AOS
   */
  refreshAOS() {
    AOS.refresh();
  }

  /**
   * [POST] Request to increase count and viewer click
   * @param postData
   */
  clickAndViewerUpdate(postData: { propertyId: number, key: string, deviceId: string }): any {
    return this.http.post(`${environment.apiUrl}/showroom/click/viewer/update`, postData).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [POST] Request to update viewer count
   * @param requestBody Request body
   */
  updateViewerCount(requestBody: {propertyId: number, deviceId: string}) {
    return this.http.post(`${environment.apiUrl}/showroom/viewer/update`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    ).subscribe({
      next: () => {
      },
      error: () => {}
    })
  }


  /**
   * [POST] Request to stop tracking device ID
   * @param deviceId
   */
  stopTrackingDeviceId(deviceId:string) {
    const requestBody = {deviceId: deviceId, key: 'showroom'}
    return this.http.post(`${environment.apiUrl}/device/blacklist`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * Scroll carousel on wheel event
   * @param event
   * @param carouselRef
   */
  scrollCarousel(event: WheelEvent, carouselRef: CarouselComponent) {
    if(event.deltaX > 0) {
      carouselRef.nextPage();
    } else if(event.deltaX < 0) {
      carouselRef.previousPage();
    }
  }

  blurButton(event: any) {
    const element = event.target as HTMLAnchorElement;
    element.blur();
  }

}
