import {Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, take} from "rxjs";

interface PropertyDetails {
  id: number,
  companyId: number
}

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  isScrollingTop = new BehaviorSubject(false);
  isScrollingTopUpdated: boolean = false;


  PropertyId: WritableSignal<string> = signal<string>('');
  isMobileNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  PROPERTY_DETAILS: WritableSignal<PropertyDetails> = signal<PropertyDetails>({ id: 0, companyId: 0 })

  constructor(
    private http: HttpClient
  ) {

  }

  /**
   * [POST] API to send keep in touch email
   * @param data
   */
  sendKeepInTouchInformation(data: any) {
    return this.http.post(`${environment.apiUrl}/contact`, data).pipe(
      take(1)
    );
  }
}
