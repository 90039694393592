import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import * as amplitude from "@amplitude/analytics-browser";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map, take} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class EventDBLogService {

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) { }

  /**
   * To save event log in db
   * @param eventName
   * @param eventProperties
   */
  updateEventLog(eventName: string, eventProperties: any) {
    let postData = {
      deviceIdAmplitude: amplitude.getDeviceId() ? amplitude.getDeviceId() : '',
      distinctIdInternal: this.getUniqueId,
      eventName: eventName,
      eventProperty: eventProperties,
    }
    if(isPlatformBrowser(this.platformId)) {
      this.saveEventLog(postData);
    }
  }

  /**
   * [POST] Request to save log on db
   * @param requestBody Request body
   */
  saveEventLog(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/showroom/events`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    ).subscribe({
      next: () => {
      },
      error: () => {}
    })
  }

  get getUniqueId() {
    if(isPlatformBrowser(this.platformId)) {
      if(localStorage.getItem('distinctIdInternal')) {
        return localStorage.getItem('distinctIdInternal');
      }
      const uniqueId = this.generateUniqueId()
      localStorage.setItem('distinctIdInternal', uniqueId);
      return uniqueId;
    }
    return this.generateUniqueId();
  }


  /**
   * Return unique id
   */
  generateUniqueId(): string {
    const currentTime = Date.now() * 1000 + performance.now() % 1000; // combining seconds with microseconds part
    let randomId = '';
    for (let i = 0; i < 6; i++) {
      randomId += this.getRandomChar();
    }
    return `${(currentTime % 1000000).toString().padStart(6, '0')}${randomId}_${Date.now()}`;
  }

  /**
   * Return random character
   */
  getRandomChar(): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return chars.charAt(Math.floor(Math.random() * chars.length));
  }

}
