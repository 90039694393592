import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import {Subject, Subscription, throttleTime} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Directive({
    selector: '[appWheelThrottle]',
    standalone: false
})
export class WheelThrottleDirective implements OnInit, OnDestroy{

  @Input() throttleTime = 800;

  @Output()
  throttledWheel = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription | undefined;

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object
  ) { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe({
      next: (e) => {
        this.emitThrottledClick(e)
      }
    });
  }

  emitThrottledClick(e:any) {
    this.throttledWheel.emit(e);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    if (this.subscription === undefined) {
    } else {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('wheel', ['$event'])
  clickEvent(event: WheelEvent) {
    if(isPlatformBrowser(this.platformId)) {
      if(window.innerWidth > 767 ) {
        if(!!event.deltaX) {
          event.preventDefault();
          event.stopPropagation();
          if(event.deltaX < -9 || event.deltaX > 9) {
            this.clicks.next(event);
          }
        }
      }
    }
  }

}
